import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { COLOR_FONT_0, COLOR_STACKTRENDS_0 } from '../colors';

import { LandingCenter } from './LandingLayout';

/**
 *
 */
const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 64px;
  font-size: 14px;
  color: ${COLOR_FONT_0};

  a {
    margin-right: 24px;
    font-weight: 400;
    color: ${COLOR_FONT_0};

    &:visited {
      color: ${COLOR_FONT_0};
    }

    &:hover {
      color: ${COLOR_FONT_0};
    }
  }
`;

/**
 *
 */
const StyledLandingFooter = styled.footer`
  margin-top: 24px;
  background-color: ${COLOR_STACKTRENDS_0};
`;

export function LandingFooter() {
  return (
    <StyledLandingFooter>
      <LandingCenter>
        <FooterContainer>
          <Link to="/faq/">FAQ</Link>
          <Link to="/about/">About</Link>
          &copy; StackTrends {new Date().getFullYear()}. All rights reserved.
        </FooterContainer>
      </LandingCenter>
    </StyledLandingFooter>
  );
}
