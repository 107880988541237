import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

import { COLOR_FONT_0 } from '../colors';

/**
 *
 */
const StyledLogo = styled(Link)`
  line-height: 64px;
  font-size: 20px;
  font-weight: 400;
  color: ${COLOR_FONT_0};

  &:visited {
    color: ${COLOR_FONT_0};
  }

  &:hover {
    color: ${COLOR_FONT_0};
  }

  svg {
    margin-left: 4px;
  }
`;

export function Logo() {
  return (
    <StyledLogo to={'/'}>
      StackTrends
      <FontAwesomeIcon icon={faChartLine} />
    </StyledLogo>
  );
}
