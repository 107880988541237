import styled from 'styled-components';

/**
 *
 */
export const AppCenter = styled.div`
  overflow: hidden;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 8px 0 0;

  @media all and (min-width: 768px) {
    padding: 0 16px 0 0;
  }

  @media all and (min-width: 1440px) {
    padding: 0 24px 0 0;
  }
`;
