// -- surfaces --
export const COLOR_SURFACE_0 = '#192037';
export const COLOR_SURFACE_1 = '#27304d';
export const COLOR_SURFACE_1_RELIEF = '#313a57';
export const COLOR_SURFACE_2 = '#131931';
export const COLOR_SURFACE_2_RELIEF = '#1d233b';

// -- fonts --
export const COLOR_FONT_0 = '#ffffff';
export const COLOR_FONT_1 = '#ddd9e2';
export const COLOR_FONT_2 = '#c9c5ce';

export const COLOR_LINK_0 = '#2196f3';

// -- brand --
export const COLOR_STACKTRENDS_0 = '#2196f3';
// export const COLOR_STACKTRENDS_0 = '#f64a8b';

export const COLOR_COUNT_0 = '#9c27b0';
// export const COLOR_COUNT_0 = '#8255fd';
export const COLOR_COUNT_0_TRANS = 'rgba(156,39,176,0.2)';

export const COLOR_GAIN_0 = '#4caf50';
// export const COLOR_GAIN_0 = '#00e9d3';

export const COLOR_LOSS_0 = '#e91e63';
// export const COLOR_LOSS_0 = '#f64a8b';

export const COLOR_RANK_0 = '#4caf50';
// export const COLOR_RANK_0 = '#00e9d3';

export const COLOR_SEARCH_0 = '#2196f3';
// export const COLOR_SEARCH_0 = '#8255fd';
