import { createGlobalStyle } from 'styled-components';

import { COLOR_FONT_0, COLOR_LINK_0, COLOR_SURFACE_0 } from './colors';

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    min-width: 360px;
    margin: 0;
    padding: 0;
  }

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    height: 100vh;
  }

  body {
    height: 100vh;
    background: ${COLOR_SURFACE_0};

    line-height: 200%;
    text-align: left;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 200;
    color: ${COLOR_FONT_0};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
  }

  a,
  a:visited,
  img,
  select {
    outline: none;
    border: none;
    border-image: none;
    border-image-width: 0px;
  }

  a,
  a:visited {
    text-decoration: none;
    cursor: pointer;
    color: ${COLOR_LINK_0};
  }

  a:hover {
    color: ${COLOR_FONT_0};
  }

  ul {
    padding: 0;
    list-style: none;
  }

  li {
    display: block;
  }

  /* ------------- react-burger-menu ------------- */

  .bm-burger-button {
    position: fixed;
    width: 30px;
    height: 24px;
    top: 20px;
    right: 8px;
  }

  .bm-burger-bars {
    background: ${COLOR_FONT_0};
  }
  
  .bm-burger-bars-hover {
    background: ${COLOR_FONT_0};
  }
  
  .bm-cross-button {
    height: 36px;
    width: 36px;
  }
  
  .bm-cross {
    background: ${COLOR_FONT_0};
  }

  .bm-menu {
    background-color: ${COLOR_LINK_0};
  }

  @media all and (min-width: 1366px) {
    .bm-burger-button {
      display: none;
    }
  }
`;
