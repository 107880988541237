import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { COLOR_LINK_0, COLOR_SURFACE_0 } from '../colors';
import { GlobalStyles } from '../global';
import favicon from '../../static/images/favicon.ico';

import { LandingFooter } from './LandingFooter';
import { LandingHeader } from './LandingHeader';
import { Burger } from '../components/Burger';

/**
 *
 */
export const LandingCenter = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 8px;
`;

/**
 *
 */
const LandingMain = styled.main`
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 64px;

  @media all and (min-width: 1366px) {
    overflow: visible;
    margin-top: 0;
  }
`;

/**
 *
 */
const LandingWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_SURFACE_0};

  @media all and (min-width: 1366px) {
    height: auto;
  }
`;

/**
 *
 */
const StyledLandingLayout = styled.div`
  background-color: ${COLOR_LINK_0};
`;

export function LandingLayout(props) {
  return (
    <>
      <GlobalStyles />
      <StyledLandingLayout id="layout">
        <Helmet>
          <meta name="viewport" content="width=device-width; initial-scale=1.0" />
          <link rel="icon" href={favicon} />
        </Helmet>

        <Burger />

        <LandingWrapper id="wrapper">
          <LandingHeader />

          <LandingMain>
            {props.children}

            <LandingFooter />
          </LandingMain>
        </LandingWrapper>
      </StyledLandingLayout>
    </>
  );
}
