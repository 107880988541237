import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faCheckCircle,
  faCodeBranch,
  faCompass,
  faMapMarkerAlt,
  faTachometerAlt
} from '@fortawesome/free-solid-svg-icons';

import {
  COLOR_COUNT_0,
  COLOR_FONT_0,
  COLOR_FONT_1,
  COLOR_STACKTRENDS_0,
  COLOR_SURFACE_2,
  COLOR_SURFACE_2_RELIEF
} from '../colors';
import { formatCountValue, formatYear } from '../utils';

/**
 *
 */
const AppData = PropTypes.shape({
  currentPeriod: PropTypes.shape({
    id: PropTypes.number.isRequired,
    month_display: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired
  }).isRequired,
  metrics: PropTypes.shape({
    currentListingCount: PropTypes.number.isRequired,
    sourceCount: PropTypes.number.isRequired,
    technologyCount: PropTypes.number.isRequired,
    totalListingCount: PropTypes.number.isRequired
  }).isRequired
}).isRequired;

/**
 *
 */
const PrimaryNavLink = styled(Link)`
  padding: 0 24px;
  line-height: 64px;
  font-size: 16px;
  font-weight: 400;
  color: ${COLOR_FONT_0};

  &:visited {
    color: ${COLOR_FONT_0};
  }

  &:hover {
    background-color: ${COLOR_SURFACE_2_RELIEF};
    color: ${COLOR_FONT_0};
  }

  &.active {
    background-color: ${COLOR_SURFACE_2_RELIEF};

    &:hover {
      background-color: ${COLOR_SURFACE_2_RELIEF};
    }
  }

  svg {
    margin-right: 8px;
    font-size: 20px;
  }
`;

/**
 *
 */
const PrimaryNavHomeLink = styled(Link)`
  display: block;
  padding: 0 24px;
  background-color: ${COLOR_STACKTRENDS_0};
  line-height: 64px;
  font-size: 20px;
  font-weight: 400;
  color: ${COLOR_FONT_0};

  &:visited {
    color: ${COLOR_FONT_0};
  }

  &:hover {
    color: ${COLOR_FONT_0};
  }

  svg {
    margin-left: 4px;
    font-size: 20px;
  }
`;

/**
 *
 */
const StyledPrimaryNav = styled.nav`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

/**
 *
 */
function AppPrimaryNav() {
  return (
    <StyledPrimaryNav>
      {/* -- home -- */}
      <PrimaryNavHomeLink to="/">
        StackTrends
        <FontAwesomeIcon icon={faChartLine} />
      </PrimaryNavHomeLink>

      {/* -- overview -- */}
      <PrimaryNavLink to="/overview/" activeClassName="active">
        <FontAwesomeIcon icon={faTachometerAlt} />
        Overview
      </PrimaryNavLink>

      {/* -- tech -- */}
      <PrimaryNavLink to="/technologies/" activeClassName="active" partiallyActive={true}>
        <FontAwesomeIcon icon={faCodeBranch} />
        Technologies
      </PrimaryNavLink>

      {/* -- states -- */}
      <PrimaryNavLink to="/states/" activeClassName="active" partiallyActive={true}>
        <FontAwesomeIcon icon={faCompass} />
        States
      </PrimaryNavLink>

      {/* -- cities -- */}
      <PrimaryNavLink to="/cities/" activeClassName="active" partiallyActive={true}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        Cities
      </PrimaryNavLink>
    </StyledPrimaryNav>
  );
}

/**
 *
 */
const StyledSecondaryNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 8px 24px;
  border-bottom: 1px solid ${COLOR_SURFACE_2_RELIEF};

  a,
  a:visited {
    line-height: 36px;
    color: ${COLOR_FONT_0};

    &:hover {
      color: ${COLOR_FONT_1};
    }
  }
`;

/**
 *
 */
function AppSecondaryNav() {
  return (
    <StyledSecondaryNav>
      {/* -- About -- */}
      <Link to="/about/" activeClassName="active" partiallyActive={true}>
        About
      </Link>

      {/* -- FAQ -- */}
      <Link to="/faq/" activeClassName="active" partiallyActive={true}>
        FAQ
      </Link>
    </StyledSecondaryNav>
  );
}

/**
 *
 */
const StyledAppMetrics = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  font-size: 14px;

  svg {
    margin-right: 4px;
  }
`;

function AppMetrics(props) {
  const { currentPeriod, metrics } = props.data;

  return (
    <StyledAppMetrics>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: COLOR_COUNT_0 }} />{' '}
        <b>+{formatYear(parseFloat((currentPeriod.id / 12).toFixed(1)))} Years</b> of History
      </li>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: COLOR_COUNT_0 }} />{' '}
        <b>{formatCountValue(metrics.totalListingCount)}</b> Total Listings
      </li>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: COLOR_COUNT_0 }} />{' '}
        <b>{formatCountValue(metrics.currentListingCount)}</b> Current Listings
      </li>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: COLOR_COUNT_0 }} /> <b>{metrics.technologyCount}</b>{' '}
        Technologies
      </li>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: COLOR_COUNT_0 }} /> <b>{metrics.sourceCount}</b> Job Sites, <b>{metrics.queryCount}</b> Roles
      </li>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: COLOR_COUNT_0 }} /> Updated{' '}
        <b>
          {currentPeriod.month_display}, {currentPeriod.year}
        </b>
      </li>
    </StyledAppMetrics>
  );
}

AppMetrics.propTypes = {
  data: AppData
};

/**
 *
 */
const StyledAppSidebar = styled.div`
  display: none;

  @media all and (min-width: 1366px) {
    display: flex;
    flex-direction: column;
    width: 272px;
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: ${COLOR_SURFACE_2};
  }

  @media all and (min-width: 1440px) {
    width: 300px;
  }
`;

export function AppSidebar(props) {
  return (
    <StyledAppSidebar>
      <AppPrimaryNav />

      <AppSecondaryNav />

      <AppMetrics data={props.data} />
    </StyledAppSidebar>
  );
}

AppSidebar.propTypes = {
  data: AppData
};
