import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch, faCompass, faMapMarkerAlt, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { reveal as Menu } from 'react-burger-menu';

import { COLOR_FONT_0, COLOR_LINK_0 } from '../colors';

/**
 *
 */
const BurgerLink = styled(Link)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  line-height: 63px;
  text-indent: 24px;
  font-size: 18px;
  font-weight: 400;
  color: ${COLOR_FONT_0};

  &:visited {
    color: ${COLOR_FONT_0};
  }

  &:hover {
    color: ${COLOR_FONT_0};
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid ${COLOR_LINK_0};
    color: ${COLOR_FONT_0};

    &:hover {
      color: ${COLOR_FONT_0};
      cursor: initial;
    }
  }

  svg {
    margin-right: 8px;
    font-size: 20px;
  }
`;

/**
 *
 */
export function Burger() {
  return (
    <Menu right width={272} pageWrapId="wrapper" outerContainerId="layout">
      {/* -- overview -- */}
      <BurgerLink to="/overview/" activeClassName="active">
        <FontAwesomeIcon icon={faTachometerAlt} />
        Overview
      </BurgerLink>

      {/* -- tech -- */}
      <BurgerLink to="/technologies/" activeClassName="active" partiallyActive={true}>
        <FontAwesomeIcon icon={faCodeBranch} />
        Technologies
      </BurgerLink>

      {/* -- states -- */}
      <BurgerLink to="/states/" activeClassName="active" partiallyActive={true}>
        <FontAwesomeIcon icon={faCompass} />
        States
      </BurgerLink>

      {/* -- cities -- */}
      <BurgerLink to="/cities/" activeClassName="active" partiallyActive={true}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        Cities
      </BurgerLink>
    </Menu>
  );
}
