/**
 *
 */
export function formatCountValue(value) {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  }
  if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return value.toLocaleString();
}

/**
 *
 */
export function formatPercent(percent) {
  let stringified = percent.toLocaleString();
  if (!/\./g.test(stringified)) {
    stringified = `${stringified}.0`;
  }
  return `${stringified}%`;
}

/**
 *
 */
 export function formatYear(year) {
  return Math.floor(year);
}
