import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { COLOR_SURFACE_0, COLOR_SURFACE_1_RELIEF } from '../colors';
import { GlobalStyles } from '../global';
import favicon from '../../static/images/favicon.ico';

import { AppCenter } from './AppCenter';
import { AppFooter } from './AppFooter';
import { AppHeader } from './AppHeader';
import { AppSidebar } from './AppSidebar';
import { Burger } from '../components/Burger';

/**
 *
 */
export const SectionTitle = styled.h2`
  overflow: hidden;
  margin: 24px 0 0 8px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 400;

  @media all and (min-width: 768px) {
    margin: 16px 0 0 16px;
  }

  @media all and (min-width: 1024px) {
    margin: 24px 0 0 24px;
  }

  @media all and (min-width: 1440px) {
    margin: 32px 0 0 24px;
    line-height: 32px;
    font-size: 24px;
  }
`;

/**
 *
 */
const PageTitleHeading = styled.h1`
  max-width: 1366px;
  margin: 0 auto;
  padding: 8px 0 0 8px;
  line-height: 48px;
  font-size: 20px;
  font-weight: 400;

  @media all and (min-width: 768px) {
    padding: 16px 0 0 16px;
    line-height: 32px;
    font-size: 24px;
  }

  @media all and (min-width: 1366px) {
    padding: 0 0 0 16px;
    line-height: 64px;
    font-size: 24px;
  }

  @media all and (min-width: 1440px) {
    padding: 0 0 0 24px;
    font-size: 28px;
  }
`;

/**
 *
 */
const StyledPageTitle = styled.div`
  overflow: hidden;

  @media all and (min-width: 1366px) {
    background-color: ${COLOR_SURFACE_1_RELIEF};
  }
`;

function PageTitle(props) {
  return (
    <StyledPageTitle>
      <PageTitleHeading>{props.children}</PageTitleHeading>
    </StyledPageTitle>
  );
}

/**
 *
 */
const AppMain = styled.main`
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 64px;

  @media all and (min-width: 1366px) {
    margin-top: 0;
  }
`;

/**
 *
 */
const App = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_SURFACE_0};

  @media all and (min-width: 1355px) {
    padding-top: 0;
  }
`;

/**
 *
 */
const AppWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

/**
 *
 */
const StyledAppLayout = styled.div`
  height: 100vh;
  background-color: ${COLOR_SURFACE_0};
`;

export function AppLayout(props) {
  return (
    <>
      <GlobalStyles />

      <StyledAppLayout id="layout">
        <Helmet>
          <meta name="viewport" content="width=device-width; initial-scale=1.0" />
          <link rel="icon" href={favicon} />
        </Helmet>

        <Burger />

        <AppWrapper id="wrapper">
          <AppSidebar data={props.data} />

          <App>
            <AppHeader />

            <AppMain>
              <PageTitle>{props.title}</PageTitle>

              <AppCenter>{props.children}</AppCenter>

              <AppFooter />
            </AppMain>
          </App>
        </AppWrapper>
      </StyledAppLayout>
    </>
  );
}

AppLayout.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};
