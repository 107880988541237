import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { COLOR_FONT_0, COLOR_STACKTRENDS_0 } from '../colors';

import { LandingCenter } from './LandingLayout';
import { Logo } from './Logo';

/**
 *
 */
const LandingHeaderLink = styled(Link)`
  display: none;

  @media all and (min-width: 1366px) {
    display: inline-block;
    margin-left: 24px;
    font-size: 18px;
    font-weight: 400;
    color: ${COLOR_FONT_0};

    &:visited {
      color: ${COLOR_FONT_0};
    }

    &:hover {
      color: ${COLOR_FONT_0};
    }
  }
`;

/**
 *
 */
const StyledLandingHeader = styled.header`
  width: 100%;
  position: fixed;
  background-color: ${COLOR_STACKTRENDS_0};
  line-height: 64px;
  z-index: 9;

  @media all and (min-width: 1366px) {
    display: block;
    position: static;
  }
`;

export function LandingHeader() {
  return (
    <StyledLandingHeader>
      <LandingCenter>
        <Logo />
        <LandingHeaderLink to="/overview/">Overview</LandingHeaderLink>
        <LandingHeaderLink to="/technologies/">Technologies</LandingHeaderLink>
        <LandingHeaderLink to="/states/">States</LandingHeaderLink>
        <LandingHeaderLink to="/cities/">Cities</LandingHeaderLink>
      </LandingCenter>
    </StyledLandingHeader>
  );
}
