import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { COLOR_FONT_0 } from '../colors';

import { AppCenter } from './AppCenter';

/**
 *
 */
const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 8px;
  line-height: 64px;
  font-size: 14px;
  color: ${COLOR_FONT_0};

  a {
    margin-right: 24px;
    font-weight: 400;
    color: ${COLOR_FONT_0};

    &:visited {
      color: ${COLOR_FONT_0};
    }

    &:hover {
      color: ${COLOR_FONT_0};
    }
  }

  @media all and (min-width: 768px) {
    padding-left: 16px;
  }

  @media all and (min-width: 1440px) {
    padding-left: 24px;
  }
`;

/**
 *
 */
const StyledAppFooter = styled.footer`
  display: block;
`;

export function AppFooter() {
  return (
    <StyledAppFooter>
      <AppCenter>
        <FooterContainer>
          <Link to="/faq/">FAQ</Link>
          <Link to="/about/">About</Link>
          &copy; StackTrends {new Date().getFullYear()}. All rights reserved.
        </FooterContainer>
      </AppCenter>
    </StyledAppFooter>
  );
}
