import React from 'react';
import styled from 'styled-components';

import { COLOR_STACKTRENDS_0 } from '../colors';

import { Logo } from './Logo';

/**
 *
 */
const StyledAppHeader = styled.header`
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  padding: 0 8px;
  background-color: ${COLOR_STACKTRENDS_0};
  z-index: 9;

  @media all and (min-width: 768px) {
    padding: 0 16px;
  }

  @media all and (min-width: 1366px) {
    display: none;
  }
`;

export function AppHeader() {
  return (
    <StyledAppHeader>
      <Logo />
    </StyledAppHeader>
  );
}
